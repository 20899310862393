<template>
  <div class="d-flex">
    <b-avatar size="23" variant="light" :src="option.avatar" />
    <span class="ml-1"> {{ option.name }} {{ option.lastname }}</span>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
export default {
    components: {
        BAvatar
    },
    props: {
        option: {
            type: Object,
            required: true
        }
    }
};
</script>

<style></style>
