<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.name)"
          :variant="`light-dark`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">{{ userData.name }} {{ userData.lastname }}</h4>
      <div class="d-flex flex-wrap flex-column">
        <b-form-file
          id="file-small"
          size="sm"
          class="my-1 w-25"
          accept="image/png, image/jpeg"
          @change="onFileChange($event)"
        ></b-form-file>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form autocomplete="nope">
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Name')" label-for="name">
            <b-form-input id="name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Last Name')" label-for="last-name">
            <b-form-input id="last-name" v-model="userData.lastname" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Form.Status')" label-for="user-status">
            <v-select
              v-model="userData.status"
              :options="statusOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="userData.roles"
              :options="rolesData"
              :reduce="(val) => val.role_id"
              multiple
              label="name"
            />
          </b-form-group>
        </b-col>
        <!-- Field: no_validation_changes -->
        <b-col cols="12" md="4">
          <b-form-group label="Unlimited Facilities Changes">
            <v-select
              v-model="userData.no_validation_changes"
              :options="statusOptions"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
        <!-- password -->
        <b-col cols="12" md="4">
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="password"
            rules="required"
          >
            <b-form-group label="Password" label-for="password">
              <b-input-group>
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :type="passwordFieldType"
                  class="form-control-merge"
                  name="hidden"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <!-- Field: Email -->
      </b-row>
    </b-form>
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="saveChanges"
      :disabled="loading"
    >
      <span v-if="!loading">Save Changes</span>
      <span v-else>
        <SpinnerLoading />
      </span>
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>

    <!-- OMA TABLE -->
    <b-card class="text-center" v-if="userData.roles[0] === 4 || 5">
      <b-card-header class="p-1 justify-content-center">
        <b-card-title class="font-medium-2">
          <feather-icon icon="GitBranchIcon" size="18" />
          <span class="align-middle ml-50">{{
            isOmaRole ? "NURSE / FRONTDESK" : "OMA"
          }}</span>
        </b-card-title>
      </b-card-header>
      <v-select
        v-if="!isOmaRole"
        v-model="omaAssing"
        :options="omasOptions"
        @search="searchOma"
        label="fullname"
      >
        <template slot="option" slot-scope="option">
          <SelectUser :option="option" />
        </template>
        <template slot="selected-option" slot-scope="option">
          <SelectUser :option="option" />
        </template>
      </v-select>
      <div v-else class="d-flex justify-content-between">
        <v-select
          v-model="nurseAssing"
          :options="optionsNurse"
          class="w-75"
          @search="searchNurse"
          label="fullname"
        >
          <template slot="option" slot-scope="option">
            <SelectUser :option="option" />
          </template>
          <template slot="selected-option" slot-scope="option">
            <SelectUser :option="option" />
          </template>
        </v-select>
        <v-select
          v-model="frontDeskAssing"
          :options="optionsFrontDesk"
          class="w-75"
          @search="searchFrontDesk"
          label="fullname"
        >
          <template slot="option" slot-scope="option">
            <SelectUser :option="option" />
          </template>
          <template slot="selected-option" slot-scope="option">
            <SelectUser :option="option" />
          </template>
        </v-select>
      </div>
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChangesOma"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card>

    <!-- FACILITY PARTNET TABLE -->
    <b-card class="text-center">
      <b-card-header class="p-1 justify-content-center">
        <b-card-title class="font-medium-2">
          <feather-icon icon="HomeIcon" size="18" />
          <span class="align-middle ml-50">Facilities</span>
        </b-card-title>
      </b-card-header>
      <v-select
        v-model="userData.facilities"
        :options="facilityOptions"
        :reduce="(facility) => facility.facilities_id"
        multiple
        label="name"
      />
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChangesFacility"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card>

    <!-- CLINICA DEFAULT -->
    <b-card class="text-center">
      <b-card-header class="p-1 justify-content-center">
        <b-card-title class="font-medium-2">
          <feather-icon icon="HomeIcon" size="18" />
          <span class="align-middle ml-50">Facility Default</span>
        </b-card-title>
      </b-card-header>
      <v-select
        v-model="userData.default_facility_id"
        :options="facilityDefaultOptions"
        :reduce="(facility) => facility.facilities_id"
        label="name"
      />
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChangesFacility"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card>

    <!-- FACILITY TABLE -->
    <b-card class="text-center">
      <b-card-header class="p-1 justify-content-center">
        <b-card-title class="font-medium-2">
          <feather-icon icon="HomeIcon" size="18" />
          <span class="align-middle ml-50">Partner</span>
        </b-card-title>
      </b-card-header>
      <v-select
        v-model="userData.partner_facilities"
        :options="facilityOptions"
        :reduce="(facility) => facility.facilities_id"
        multiple
        label="name"
      />
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChangesFacilityPartner"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card>

    <!-- PERMISSION TABLE -->
    <!-- <b-card no-body class="border mt-1 text-center">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table striped responsive class="mb-0" :items="permissionsData">
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
      <b-col cols="12">
        <b-button
          variant="primary"
          class="mt-2"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="saveChanges"
          :disabled="loading"
        >
          <span v-if="!loading">Save Changes</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </b-col>
    </b-card> -->

    <!-- Action Buttons -->

    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import axiosU from "@/services/admin/user";
import axiosF from "@/services/admin/facilities";
import axiosEC from "@/services/external-settings/images";

import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import SelectUser from "@/components/SelectUser";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormFile,
    vSelect,
    SpinnerLoading,
    ToastNotification,
    SelectUser,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    rolesData: {
      type: Array,
      required: true,
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.getFacilityOptions();
    if (this.userData.roles[0] === 3) {
      this.isOmaRole = true;
      this.getIdOma();
      this.getUsers();
    } else if (this.userData.roles[0] === 4 || 5) {
      this.getIdNurse();
      this.getOmas();
    } else {
      this.isOmaRole = false;
    }
    this.userData.no_validation_changes = this.userData.no_validation_changes || false
  },
  data() {
    return {
      statusOptions: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
      isOmaRole: false,
      facilityOptions: [],
      omaAssing: [],
      nurseAssing: [],
      frontDeskAssing: [],
      omasOptions: [],
      optionsNurse: [],
      optionsFrontDesk: [],
      clearTimeoutBuscador: null,
      permissionsData: [
        {
          module: "Banners",
          index: true,
          read: true,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "Staff",
          read: false,
          write: true,
          create: false,
          delete: false,
        },
        {
          module: "Author",
          read: true,
          write: false,
          create: true,
          delete: false,
        },
        {
          module: "Contributor",
          read: false,
          write: false,
          create: false,
          delete: false,
        },
        {
          module: "User",
          read: false,
          write: false,
          create: false,
          delete: true,
        },
      ],
      fileResource: null,
      tags: "browser-upload",
      onChangePhoto: false,
      loading: false,
    };
  },
  computed: {
    facilityDefaultOptions() {
      return this.facilityOptions.filter(fac => {
        return this.userData.facilities.includes(fac.id)
      })
    }
  },
  methods: {
    avatarText(name) {
      return avatarText(name);
    },
    onFileChange(event) {
      //returns an array of files even though multiple not used
      this.fileResource = event.target.files[0];
      this.userData.avatar = URL.createObjectURL(this.fileResource);
      this.onChangePhoto = true;
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "USER");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
    viewImage(image) {
      this.viewImageBoolean = true;
      this.previewImage = image.target.src;
    },
    //
    getFacilityOptions() {
      axiosF.facilityList(20).then(({ data }) => {
        const facilityList = data.map((item) => {
          const facilities_id = item.id;
          return {
            facilities_id,
            ...item,
          };
        });
        this.facilityOptions = facilityList;
      });
    },
    //
    getOmas() {
      const data = {
        role: "OMA",
      };
      axiosU.userFilter(30, data).then(({ registro: { data } }) => {
        const userOptions = data.map((item) => {
          const fullname = `${item.name} ${item.lastname}`;
          return {
            ...item,
            fullname,
          };
        });
        this.omasOptions = userOptions;
      });
    },
    getUsers() {
      const data = {
        role: ["NURSE", "FRONT-DESK"],
      };
      axiosU.userFilter(30, data).then(({ registro: { data } }) => {
        const userOptions = data.map((item) => {
          const fullname = `${item.name} ${item.lastname}`;
          return {
            ...item,
            fullname,
          };
        });
        this.optionsNurse = userOptions.filter(
          (item) => item.roles[0].id === 4
        );
        this.optionsFrontDesk = userOptions.filter(
          (item) => item.roles[0].id === 5
        );
      });
    },
    searchOma(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["OMA"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            const userOptions = data.map((item) => {
              const fullname = `${item.name} ${item.lastname}`;
              return {
                ...item,
                fullname,
              };
            });
            this.omasOptions = userOptions;
            loading(false);
          });
        }, 400);
      }
    },
    searchNurse(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["NURSE"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            const userOptions = data.map((item) => {
              const fullname = `${item.name} ${item.lastname}`;
              return {
                ...item,
                fullname,
              };
            });
            this.optionsNurse = userOptions;
            loading(false);
          });
        }, 400);
      }
    },
    searchFrontDesk(name, loading) {
      if (name.length) {
        const datos = {
          name,
          role: ["FRONT-DESK"],
        };
        loading(true);
        clearTimeout(this.clearTimeoutBuscador);
        this.clearTimeoutBuscador = setTimeout(() => {
          axiosU.userFilter(10, datos).then(({ registro: { data } }) => {
            const userOptions = data.map((item) => {
              const fullname = `${item.name} ${item.lastname}`;
              return {
                ...item,
                fullname,
              };
            });
            this.optionsFrontDesk = userOptions;
            loading(false);
          });
        }, 400);
      }
    },
    //
    getIdNurse() {
      axiosU.ShowOmaUser(this.userData.id).then((res) => {
        if (res.length > 0) {
          const { user_omas } = res[0];
          const omaAssing = user_omas ? user_omas[0].id : null;
          axiosU.userDetail(omaAssing).then(({ registro }) => {
            this.omaAssing = registro;
            const omaFind = this.omasOptions.find(
              (item) => item.id === registro.id
            );
            if (!omaFind) {
              this.omasOptions = this.omasOptions.concat(registro);
            }
          });
        }
      });
    },
    getIdOma() {
      axiosU.usersOma(this.userData.id).then(({ registro }) => {
        const { nurses, frontDesks } = registro;
        this.nurseAssing = nurses[0];
        this.frontDeskAssing = frontDesks[0];
      });
    },
    async saveChanges() {
      if (this.onChangePhoto) {
        const photoUrl = await this.uploadPhoto(this.fileResource);
        this.userData.avatar = photoUrl;
        this.onChangePhoto = false;
      }
      axiosU
        .userUpdate(this.userData.id, this.userData)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
    saveChangesFacility() {
      const data = {
        facilities: this.userData.facilities,
        default_facility_id: this.userData.default_facility_id
      };
      axiosU
        .userUpdateFacilities(this.userData.id, data)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
    saveChangesFacilityPartner() {
      const data = {
        facilities: this.userData.partner_facilities,
        partner_id: this.userData.id,
      };
      axiosU
        .usersFacilitiesPartner(data)
        .then(() => {
          this.$refs.toast.success("User updated successfully");
        })
        .catch(() => {
          // eslint-disable-next-line no-alert
          this.$refs.toast.error("Error updating user");
        });
    },
    saveChangesOma() {
      if (this.isOmaRole) {
        // obtener los usuarios de nurse y front desk, son objetos, solo necesito el id
        const userFrontdesk = this.frontDeskAssing.id;
        const userNurse = this.nurseAssing.id;

        const data = {
          users: [userFrontdesk, userNurse],
        };
        axiosU.usersOmaUpdate(this.userData.id, data).then(() => {
          this.$refs.toast.success("User updated successfully");
        });
      } else {
        const omas_id = this.omaAssing.id;
        const data = {
          omas_id: omas_id,
        };
        axiosU
          .UserHasOma(this.userData.id, data)
          .then((res) => {
            this.$refs.toast.success("User updated successfully");
          })
          .catch(() => {
            // eslint-disable-next-line no-alert
            this.$refs.toast.error("Error updating user");
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>